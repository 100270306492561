import { graphql } from 'gatsby';
import React from 'react';

import { BaseSanityPage, ExpertiseSectionData } from 'types/schema';
import ExpertiseSection from 'components/layout/ExpertiseSection';
import MainLayout from 'components/layout/MainLayout';
import MultiSectionBackgroundWrap from 'components/layout/MultiSectionBackgroundWrap';
import PagePictureTitle from 'components/layout/PagePictureTitle';

export const pageQuery = graphql`
  query EmployerLawPageQuery {
    sanityEmployerLawPage(id: { eq: "-45b02054-d193-578b-86c3-e7b90ec956cc" }) {
      id
      meta {
        title
        description
      }
      pageTitle
      expertise {
        title
        body
        expertiseListTitle
        expertiseList
        contactCTACopy
        highlight1Title
        highlight1Body
        highlight2Title
        highlight2Body
        highlight3Title
        highlight3Body
      }
    }
  }
`;

type Props = {
  data: {
    sanityEmployerLawPage: BaseSanityPage & {
      expertise: ExpertiseSectionData;
    };
  };
};

const EmployersPage: React.FC<Props> = ({
  data: {
    sanityEmployerLawPage: {
      meta: { title, description },
      pageTitle,
      expertise,
    },
  },
}) => {
  return (
    <MainLayout seoProps={{ title, description, pagePath: '/employment-law/employers/' }}>
      <PagePictureTitle
        title={pageTitle}
        pictureUrl="https://images.unsplash.com/photo-1431400445088-1750c997c6b5"
      />
      <MultiSectionBackgroundWrap>
        <ExpertiseSection data={expertise} />
      </MultiSectionBackgroundWrap>
    </MainLayout>
  );
};

export default EmployersPage;
